<template>
  <v-container fluid>
    <v-select
      v-if="$vuetify.breakpoint.mobile"
      dense
      :items="projects"
      item-text="name"
      item-value="id"
      @change="changeProject"
      v-model="selectedItem"
      :label="$t('message.Projects')"
    />
    <v-row no-gutters align="center">
      <v-col cols="2">
        <h2 class="my-4">{{ $t('ImpactLevelItems.Impact') }}</h2>
      </v-col>
    </v-row>
    <v-data-table
      :items="ImpactLevels"
      loading="true"
      :items-per-page="15"
      :headers="HasFullAccessPermissions ? getHeaders : getHeadersNotPermissions"
      hide-default-footer
      :footer-props="footerProps"
    >
      <template #[`item.impactLevelID`]="{ item }">
        {{ $util.impactLeveIdToText(item.impactLevelID) }}
      </template>

      <template #[`item.action`]="{ item }">
        <div class="text-right">
          <v-btn icon @click="editRow(item)" :title="$t('DialogsTitles.EditImpact')">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <EditImpactLevelRow @impact-updated="saveUpdate" ref="editImpactLevelRef"></EditImpactLevelRow>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService.js';
import EditImpactLevelRow from '@/components/Dialogs/EditImpactLevelRow.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    EditImpactLevelRow,
  },
  data: () => ({
    projects: [],
    project: {},
    isEditing: false,
    loading: false,
    selectedItem: null,
    AdminOrIsInSameCompany: false,
    footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
  }),
  computed: {
    ...mapGetters(['user', 'isAdmin', 'darkMode', 'isManager', 'currentProjectId']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('ImpactLevelItems.ImpactLevel'), value: 'impactLevelID' },
        { text: t('ImpactLevelItems.TimePercentage'), value: 'timeMedian' },
        { text: t('ImpactLevelItems.ExpensesPercentage'), value: 'resourcesMedian' },
        { text: t('ImpactLevelItems.ProbabilityPercentage'), value: 'impactPercentage' },
        { text: '', value: 'action' },
      ];
    },
    getHeadersNotPermissions() {
      const t = this.$t.bind(this);
      return [
        { text: t('ImpactLevelItems.ImpactLevel'), value: 'impactLevelID' },
        { text: t('ImpactLevelItems.TimePercentage'), value: 'timeMedian' },
        { text: t('ImpactLevelItems.ExpensesPercentage'), value: 'resourcesMedian' },
        { text: t('ImpactLevelItems.ProbabilityPercentage'), value: 'impactPercentage' },
      ];
    },
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    ImpactLevels() {
      if (this.project.impact != null) {
        return this.project.impact.impactLevels;
      } else {
        return [];
      }
    },
  },
  methods: {
    async getProjects() {
      if (this.user) {
        this.selectedItem = this.currentProjectId;
        this.projects = await apiService.getProjects();
        if (!this.selectedItem) {
          this.selectedItem = (await this.projects) ? this.projects[0].id : null;
          this.$store.dispatch('SET_PROJECT_ID', this.selectedItem);
        }
      }
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      }
      if (this.project.companyId != null) {
        if (this.project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },

    async getProject() {
      this.loading = true;
      if (this.$route.params.projectId) {
        this.project = await apiService.getProjectById(this.$route.params.projectId);
      } else if (this.currentProjectId) {
        this.project = await apiService.getProjectById(this.currentProjectId);
      } else {
        this.$router.push({ name: 'Projects' });
      }
      this.project.impact.impactLevels = this.project.impact.impactLevels.reverse();
      this.IsAdminOrIsInSameCompany();
    },
    async saveUpdate() {
      this.getProject();
    },

    async changeProject() {
      this.$store.dispatch('SET_PROJECT_ID', this.selectedItem);
      this.project = await apiService.getProjectById(this.selectedItem);
    },
    editRow(impactLevel) {
      this.$refs.editImpactLevelRef.EditImpact(impactLevel);
    },
  },
  created() {
    this.getProject();
    if (this.$vuetify.breakpoint.mobile) {
      this.getProjects();
    }
  },
};
</script>

<style>
</style>